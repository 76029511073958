<template>
  <div>
    <!-- SECTION 1 -->
    <section class="section">
      <CContainer class="">
        <CRow>
          <CCol sm="12" class="text-center block-header">
            <h2>Our Cases</h2>
            <p class="text-muted">
              Take a look at our cases below to get a good idea of how we can
              help you.
            </p>
            <CButton
              class="custom-btn outline"
              type="submit"
              @click="$router.push({ path: '/contact' })"
            >
              Contact Us
            </CButton>
            <br />
          </CCol>

          <CCol sm="4" class="project-block" v-for="project in projects">
            <CImg
              v-if="project.logo"
              :src="storageURL + project.logo"
              block
              class="mb-2"
            />
            <CImg
              v-else
              src="/img/frontpage/image-placeholder.jpg"
              block
              class="mb-2"
            />

            <div class="project-location">
              <CIcon name="cil-map" />
              <span>{{ project.location }}</span>
            </div>
            <h3>{{ project.name }}</h3>
            <CLink :href="'/cases/' + project.id" class="custom-link">
              Read More <CIcon name="cil-arrow-right" />
            </CLink>
          </CCol>
        </CRow>
      </CContainer>
    </section>
  </div>
</template>

<script>
import ProjectAPI from "/app/src/api/project.js";

export default {
  name: "Cases",
  data: () => {
    return {
      projectAPI: new ProjectAPI(),
      projects: [],
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },

  created: function () {
    console.log(this.$route.name);
    this.projectAPI
      .all()
      .then((projects) => {
        this.projects = projects;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style scoped>
.project-block {
  margin-top: 30px;
  margin-bottom: 30px;
}

.project-block h3 {
  font-weight: 700;
}

.project-block p {
  font-size: 14px;
}

.project-block img {
  border-radius: 6px;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.project-block .project-location {
  text-transform: uppercase;
  color: #9e9cc1;
}

.project-block .project-location svg {
  margin-top: -4px;
  margin-right: 5px;
}
</style>
